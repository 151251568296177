@charset "UTF-8";

@media print {
  /* -------------------------------
		印刷レイアウト 2017.5.16
	---------------------------------- */

  /* A4横向き */
  @page {
    size: 29.7cm 21cm;

    /* ヘッダの日付・title文字を消す */
    margin-top: 0;
  }

  /* タイトル(ロコモ予防トレーニング)の上マージン */
  h1 {
    margin-top: 1cm;

    /* ヘッダを黒にする */
    color: #000;
  }

  /* 「利用者一覧に戻る」「比較する」ボタンを非表示にする */
  .prev,
  .btn.left,
  .btn.right {
    display: none;
  }

  /* 「印刷」ボタンを非表示にする */
  .print-btn {
    display: none;
  }

  .report #sec01 {
    display: none;
  }

  .output_way_select_box {
    display: none;
  }

  /* ヘッダの名前を黒にする */
  .report .user {
    color: #000;
  }

  /* 改ページ */
  .report #sec02,
  .report #sec03 {
    page-break-after: always;
    margin-top: 2cm;
  }
  #sec04 > .inner > section {
    page-break-after: always;
  }

  .report [id^="sec"] {
    padding-left: 0;
  }

  /* トレーニング履歴（月次）テーブルに横線を付ける */
  .tblStyle02 td {
    border-top: 1px solid #ddd;
    page-break-after: always;
  }

  /* カレンダーのデータある日に下線を付ける */
  #calendar .active {
    text-decoration: underline;
  }

  /* トレーニング履歴（月次）1つめ以外のテーブルの上下余白 */
  .report #sec02 .tblStyle02 + .tblStyle02 {
    padding-top: 8cm;
    padding-bottom: 2cm;
    margin-top: 0;
  }

  .report #sec02.locomo-monthly .tblStyle02 + .tblStyle02 {
    padding-top: 4cm;
    padding-bottom: 3.5cm;
  }

  .report #sec02.adl-monthly .tblStyle02 + .tblStyle02 {
    padding-top: 10cm;
  }

  /* 印刷時に見えるシャドウを消す */
  .report {
    box-shadow: none !important;
  }

  /* 各ブロックのマージン */
  .report #sec04 {
    margin-top: 0;
  }
  #myChart1,
  #myChart11 {
    margin-top: 2cm;
  }
  #myChart2,
  #myChart3,
  #myChart4,
  #myChart5,
  #myChart6,
  #myChart7,
  #myChart22,
  #myChart33,
  #myChart44,
  #myChart55,
  #myChart66,
  #myChart77 {
    margin-top: 2cm;
  }

  /* 印刷時のグラフのタイトルと図との隙間 */
  h3 {
    padding-bottom: 2cm;
  }
  .report.compare #sec04 h3 {
    padding-top: 6cm;
  }
  .report.compare #sec04 section:nth-of-type(1) h3 {
    padding-top: 1cm;
    padding-bottom: 1cm;
  }
}
