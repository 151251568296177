@charset "UTF-8";

ins {
  color: #000;
  text-decoration: none;
  background-color: #ff9;
}

mark {
  font-style: italic;
  font-weight: bold;
  color: #000;
  background-color: #ff9;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  cursor: help;
  border-bottom: 1px dotted;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

hr {
  display: block;
  height: 1px;
  padding: 0;
  margin: 1em 0;
  border: 0;
  border-top: 1px solid #ccc;
}

input,
select {
  vertical-align: middle;
}

.moffcheck {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 210mm;
  height: 297mm;
  padding: 5mm;
}

.moffcheck img {
  display: inline-block;
  width: 100%;
}

/* TUG周期別の秒数の年月部分 */
.moffcheck .table_left {
  width: 41mm;
}

/* TUG周期別の秒数 */
.moffcheck .table_right {
  width: 100px;
}

.moffcheck .moffcheck_name {
  position: absolute;
  top: 8mm;
  right: 5mm;
  font-size: 14pt;
  font-weight: bold;
  color: #e58e38 !important;
}

.moffcheck .moffcheck_logo {
  width: 58mm;
}

.moffcheck .moffcheck_user {
  width: 100%;
  font-size: 12.5pt;
  text-align: center;
  -webkit-box-shadow: 0 400px 0 #e58e38 inset !important;
  box-shadow: 0 400px 0 #e58e38 inset !important;
}

/* 利用者の周りのオレンジ枠 */
.moffcheck .moffcheck_user .moffcheck_user__inner {
  border: 1mm solid #e58e38;
}

/* 利用者の各項目の区切りオレンジ枠 */
.moffcheck .moffcheck_user td {
  padding: 3mm 0;

  /* borderだとSafariで区切りオレンジ枠太くなりすぎる */

  /* border: 1mm solid #e58e38; */
  border-left: 1mm solid #e58e38;
}

/* Internet Explorerの利用者の左右のオレンジ枠線 */
@media all and (-ms-high-contrast: none) {
  .moffcheck .moffcheck_user td {
    border: 1mm solid #e58e38;
  }
}

.moffcheck .moffcheck_user td.user_name {
  width: 40mm;
  color: #fff !important;
}

.moffcheck .moffcheck_user .user_sex {
  width: 35mm;
}

.moffcheck .moffcheck_user .user_birthday {
  width: 45mm;
}

.moffcheck .moffcheck_user .user_old {
  width: 35mm;
}

.moffcheck .moffcheck_user td:not(.user_name) {
  -webkit-box-shadow: 0 400px 0 #fff inset !important;
  box-shadow: 0 400px 0 #fff inset !important;
}

.moffcheck .moffcheck_score {
  width: 100%;
  text-align: center;
}

/* オレンジ色と白い文字 */
.moffcheck .moffcheck_score .moffcheck_score__col {
  color: #fff !important;
  -webkit-box-shadow: 0 400px 0 #e58e38 inset !important;
  box-shadow: 0 400px 0 #e58e38 inset !important;
}

.moffcheck_score_empty {
  height: 44px;
}

/* 白い線(Safariでならないため) */
.moffcheck .moffcheck_score .moffcheck_score__inner td {
  position: relative;
  border-top: 1mm solid #fff;
}

/* 枠の白い線(オレンジの上や、灰色、水色部分の上) */
.moffcheck .moffcheck_score .moffcheck_score__inner + .moffcheck_score__inner {
  height: 45px;
  border-top: 1mm solid #fff;
}

.moffcheck .moffcheck_tug .moffcheck_tug__inner td {
  padding: 2.2mm 0;
}

/* 開眼片足立ちの秒数 */
.moffcheck .moffcheck_graph .moffcheck_graph__table_head td {
  padding: 3.58mm 0;
  font-size: 10pt;
  -webkit-box-shadow: 0 400px 0 #e5e5e5 inset !important;
  box-shadow: 0 400px 0 #e5e5e5 inset !important;
}

.moffcheck .moffcheck_graph .moffcheck_graph__table_body td {
  position: relative;
}

.moffcheck .moffcheck_graph .moffcheck_graph__table tr td {
  /* width: calc(100% / 8); */

  /* Edgeでは反映されなかったので実計算 */
  width: 12.5%;
  vertical-align: middle;
}

.moffcheck .moffcheck_graph .moffcheck_graph__table td + td {
  border-left: 1mm solid #fff;
}

/* 枠の白い線 */
.moffcheck .moffcheck_score .moffcheck_score__inner td:not(:first-child) {
  border-left: 1mm solid #fff;
}

/* 枠の白い線 */
.moffcheck .moffcheck_score .moffcheck_score__inner:first-child {
  color: #fff !important;
}

.moffcheck .moffcheck_score .moffcheck_score__inner:first-child td {
  padding: 2mm 0;
  font-size: 10pt;
}

.moffcheck .moffcheck_score .moffcheck_score__inner:not(:first-child) td {
  padding: 3.3mm 0;
  font-size: 12.5pt;
}

.moffcheck .moffcheck_tug .moffcheck_tug__inner td:not(:first-child) {
  border-left: 1mm solid #fff;
  -webkit-box-shadow: 0 400px 0 #e5e5e5 inset !important;
  box-shadow: 0 400px 0 #e5e5e5 inset !important;
}

.moffcheck .moffcheck_graph .moffcheck_graph__table_body td:first-child {
  padding: 3mm 0;
  font-size: 10pt;

  /* Safariで最後の表の灰色の白線 */
  border-top: 1mm solid #fff;
  -webkit-box-shadow: 0 400px 0 #e5e5e5 inset !important;
  box-shadow: 0 400px 0 #e5e5e5 inset !important;
}

.moffcheck .moffcheck_graph .moffcheck_graph__table_body td:not(:first-child) {
  line-height: 0;
  vertical-align: middle;
}

.moffcheck .moffcheck_score .moffcheck_score__inner:first-child td:first-child {
  width: 40mm;
  -webkit-box-shadow: 0 400px 0 #e58e38 inset !important;
  box-shadow: 0 400px 0 #e58e38 inset !important;
}

.moffcheck
  .moffcheck_score
  .moffcheck_score__inner:first-child
  td:nth-child(2) {
  width: 65mm;
  -webkit-box-shadow: 0 400px 0 #e58e38 inset !important;
  box-shadow: 0 400px 0 #e58e38 inset !important;
}

.moffcheck
  .moffcheck_score
  .moffcheck_score__inner:first-child
  td:nth-child(3) {
  width: 25mm;
  -webkit-box-shadow: 0 400px 0 #eabb57 inset !important;
  box-shadow: 0 400px 0 #eabb57 inset !important;
}

.moffcheck
  .moffcheck_score
  .moffcheck_score__inner:first-child
  td:nth-child(4) {
  width: 25mm;
  -webkit-box-shadow: 0 400px 0 #eabb57 inset !important;
  box-shadow: 0 400px 0 #eabb57 inset !important;
}

.moffcheck
  .moffcheck_score
  .moffcheck_score__inner:first-child
  td:nth-child(5) {
  /* Firefoxでは0にすると消えるため */

  /* width: 0mm; */
  -webkit-box-shadow: 0 400px 0 #56a1ba inset !important;
  box-shadow: 0 400px 0 #56a1ba inset !important;
}

/* グレー部分 */
.moffcheck .moffcheck_score .moffcheck_score__inner:not(:first-child) {
  background-color: #e5e5e5; /* Safariはこれがないとグレーにならない */
  -webkit-box-shadow: 0 400px 0 #e5e5e5 inset !important;
  box-shadow: 0 400px 0 #e5e5e5 inset !important;
}

/* 水色部分 */
.moffcheck
  .moffcheck_score
  .moffcheck_score__inner:not(:first-child)
  td:last-child {
  /* Safariはこれがないと水色に線が入らない */
  border-top: 1mm solid #fff;
  -webkit-box-shadow: 0 400px 0 #b9e3f0 inset !important;
  box-shadow: 0 400px 0 #b9e3f0 inset !important;
}

.moffcheck .moffcheck_score .arrow_icon {
  position: absolute;
  top: 3.5mm;
  right: 2mm;
  width: 5mm;
}

.moffcheck .moffcheck_tug {
  width: 100%;
  text-align: center;
}

.moffcheck .moffcheck_tug .moffcheck_tug__inner:first-child td:first-child {
  color: #fff !important;
  -webkit-box-shadow: 0 400px 0 #e58e38 inset !important;
  box-shadow: 0 400px 0 #e58e38 inset !important;
}

.moffcheck .moffcheck_tug .moffcheck_tug__inner:nth-child(2) td:first-child {
  color: #fff !important;
  -webkit-box-shadow: 0 400px 0 #eabb57 inset !important;
  box-shadow: 0 400px 0 #eabb57 inset !important;
}

.moffcheck .moffcheck_tug .moffcheck_tug__inner:nth-child(3) td:first-child {
  color: #fff !important;
  -webkit-box-shadow: 0 400px 0 #eabb57 inset !important;
  box-shadow: 0 400px 0 #eabb57 inset !important;
}

.moffcheck .moffcheck_tug .moffcheck_tug__inner + .moffcheck_tug__inner {
  border-top: 1mm solid #fff;
}

.moffcheck .moffcheck_graph {
  width: 100%;
  zoom: 1;
  page-break-inside: avoid;
}

.moffcheck .moffcheck_graph::after,
.moffcheck .moffcheck_graph::before {
  display: table;
  content: "";
}

.moffcheck .moffcheck_graph::after {
  clear: both;
}

.moffcheck .moffcheck_graph .moffcheck_graph__l {
  float: left;
  width: 6.2%;
}

.moffcheck .moffcheck_graph .moffcheck_graph__r {
  float: left;
  width: 93.8%;
}

.moffcheck .moffcheck_graph .moffcheck_graph__r img {
  display: block;
}

.moffcheck .moffcheck_graph .moffcheck_graph__table {
  width: 100%;
  text-align: center;
}

.moffcheck .moffcheck_graph .moffcheck_graph__table tr + tr {
  border-top: 1mm solid #fff;
}

/* Edgeのみ反映 */
@supports (-ms-ime-align: auto) {
  .moffcheck .moffcheck_graph .moffcheck_graph__table_head td {
    /* Edgeのみ開眼片足立ちの秒数の下部分をかえる */
    padding: 3.2mm 0;
  }
}

.moffcheck
  .moffcheck_graph
  .moffcheck_graph__table_body
  td:not(:first-child)
  img {
  height: 11.8mm;
}

.moffcheck .moffcheck_graph .moffcheck_graph__table_map {
  position: relative;
}

.moffcheck .moffcheck_graph .moffcheck_graph__pin_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.moffcheck .moffcheck_graph .moffcheck_graph__pin_wrap .moffcheck_graph__pin {
  display: inline-block;
  width: 5mm;
  height: auto !important;
  margin-top: 1.3mm;
  text-align: center;
}

.moffcheck .moffcheck_graph .moffcheck_graph__point {
  position: absolute;
  width: 16mm;
}

.moffcheck .moffcheck_graph .moffcheck_graph__point.is__jul {
  top: 26mm;
  left: 122mm;
}

.moffcheck .moffcheck_graph .moffcheck_graph__point.is__jun {
  top: 77mm;
  left: 99mm;
}

.moffcheck .moffcheck_graph .moffcheck_graph__point.is__may {
  top: 79mm;
  left: 144mm;
}
