@charset "UTF-8";
* {
  box-sizing: border-box;
}
html,
body {
  width: auto;
  height: auto;
}
body {
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo,
    メイリオ, Osaka, "MS PGothic", arial, helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.75;
  background: url(../img/back_image.png) repeat-y;
}

button {
  display: block;
  cursor: pointer;
  border: none;
}

button:focus {
  outline: 0;
}

/* フィット */
#root {
  height: 100%;
}

/* フィット */
.fit {
  height: 100%;
}

#wrapper {
  min-height: 100%;
  margin-top: 50px;
  background-size: 100%;
}

#wrapper .contentIn {
  width: 1004px;
  max-width: 100%;
  padding: 20px 10px 24px;
  margin: 0 auto;
}

/* 長期変化の日付のフォント */
input[type="month"] {
  /* windowsレスポンシブ対応 */
  width: 28%;
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo,
    メイリオ, Osaka, "MS PGothic", arial, helvetica, sans-serif;
}

/* タイトルの下の線の距離やフォント大きさ */
h2,
h3 {
  font-weight: 500;
  line-height: 1.1;
}

.report {
  padding-bottom: 75px;
  background: #fff;
  border-radius: 10px;
  -moz-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
}

/* canvasレスポンシブ */
#canvas {
  width: 100% !important;
  height: 100% !important;
}
