@charset "UTF-8";

h1 {
  color: #fff;
  text-align: center;
}
h2 {
  font-size: 18px !important;
  font-weight: normal;
}

a {
  display: block;
  font-weight: normal;
  color: #000;
  text-decoration: none;
}
.disable a {
  pointer-events: none;
}

/* =================================
	input reset
================================= */
input[type="submit"] {
  border-style: none;
}
input[type="button"] {
  border-style: none;
}
